<template>
    <div class="sms_template_list_add">
      <el-card>
        <el-page-header @back="goBack" style="margin-bottom: 20px;"></el-page-header>
        <nav class="input flex-align" style="width: auto">
          <div>
            {{pageData.name}}
          </div>
        </nav>
        <nav class="input" style="width: auto">
          <el-input v-model="exhibitorSearch.keyword" size="medium" placeholder="请输入企业名称" />
        </nav>
        <nav class="input" style="width: 150px">
          <el-button size="small" @click="reset">重置</el-button>
          <el-button type="primary" size="small" @click="$refs.table.reload()">{{ "搜索" }}</el-button>
        </nav>
        <nav class="input" style="width: auto">
          <el-button type="primary" size="small" @click="addEnterpriseDialogVisible=true">批量添加展商</el-button>
        </nav>
      </el-card>
      <el-card  style="margin-top: 10px">
        <z-table ref="table" v-model="list" @query="getExhibitorList" fullscreenLoading>
          <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
            <el-table-column prop="id" label="ID"> </el-table-column>
            <el-table-column prop="enterpriseName" label="绑定企业">
            <template #default="{row}">
              <span style="position: relative">
               {{ row.enterpriseName }}
               <span v-if="row.isUpdate" style="   position: absolute;top: -2px;
    right: -29px;
    width: 30px;
    height: 12px;
    background: linear-gradient(-50deg, #ff2f37, #ff8c18);
    border-radius: 100px;
    font-size: 10px;
    color: #fff;
    line-height: 11px;
    text-align: center;z-index: 99">new</span>
             </span>
            </template>
            </el-table-column>
            <el-table-column prop="code" label="展位号">
              <template #default="{row}">
                <Remark :row="row" url="/admin/Exhibition/editExhibitorCode" @getlist="getlist" rowKey="code"></Remark>
              </template>
            </el-table-column>
            <el-table-column prop="eaName" label="账号"> </el-table-column>
            <el-table-column prop="eaPassword" label="密码"> </el-table-column>
            <el-table-column prop="eaPassword" label="排序">
              <template #default="{row}">
                <Remark :row="row" url="/admin/Exhibition/editExhibitorSort" @getlist="getlist" rowKey="sort"></Remark>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"> </el-table-column>
            <el-table-column prop="" label="渠道码" width="220px">
              <template slot-scope="{ row }">
                <el-image
                    v-if="row.couponUrl"
                    style="width: 80px"
                    :src="row.couponUrl"
                    :preview-src-list="[ row.couponUrl]"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" width="300px">
              <template slot-scope="{ row }">
                <el-button
                    @click="handleView(row)"
                    type="text"
                    icon="el-icon-edit"
                    size="mini"
                >展商配置</el-button>
                <el-button
                    @click="onDelete(row)"
                    type="text"
                    icon="el-icon-delete"
                    size="mini"
                    style="color: red;"
                >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </z-table>
      </el-card>
      <el-dialog
          title="展商列表"
          :visible.sync="addEnterpriseDialogVisible"
          width="60%"
          @close="toggleSelection()"
          center>
        <nav class="input" style="width: auto">
          <el-input v-model="search.keyword" size="medium" placeholder="请输入企业名称" />
        </nav>
        <nav class="input" style="width: 150px">
          <el-button size="small" @click="resetList">重置</el-button>
          <el-button type="primary" size="small" @click="$refs.exhibitorTable.reload()">{{ "搜索" }}</el-button>
        </nav>
        <z-table ref="exhibitorTable" v-model="exhibitorList" @query="queryList" fullscreenLoading>
          <el-table
              ref="multipleTable"
              :row-key="getRowKey"
              @selection-change="handleSelectionChange"
              :data="exhibitorList" style="width: 100%" border stripe :header-cell-style="rowClass">
            <el-table-column
                type="selection"
                width="55"
                :reserve-selection="true">
            </el-table-column>
            <el-table-column prop="id" label="ID" > </el-table-column>
            <el-table-column prop="enterpriseName" label="绑定企业"> </el-table-column>
            <el-table-column prop="name" label="账号"> </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
            <el-table-column prop="createTime" label="创建时间"> </el-table-column>
<!--            <el-table-column label="操作">-->
<!--              <template slot-scope="{ row }">-->
<!--                <el-button-->
<!--                    @click="onDelete(row)"-->
<!--                    type="text"-->
<!--                    icon="el-icon-delete"-->
<!--                    size="mini"-->
<!--                    style="color: red;"-->
<!--                >删除</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->
          </el-table>
        </z-table>
        <span slot="footer" class="dialog-footer">
    <el-button @click="toggleSelection()">取 消</el-button>
    <el-button type="primary" @click="addEnterprise">确 定</el-button>
  </span>
      </el-dialog>
    </div>
</template>
<script>
import list from "/src/mixin/list.js";
import {debounce} from "@/utils/methods";
import Img from "@/views/enterprise/enterprise_cert_list/img.vue";
import exhibitor from "@/views/enterprise/exhibitor.vue";
import params from "@/views/goods/params.vue";
export default {
  computed: {
    exhibitor() {
      return exhibitor
    }
  },
  components: {Img},
  mixins: [list],
  data() {
    return {
      pageData:{
        name:'展商活动'
      },
      search: {
      },
      addForm:{
        categoryId:"1",
        enterpriseId:""
      },
      exhibitorForm:{
        ids:"",
        exhibitionId:""
      },
      exhibitorSearch:{
        keyword:"",
        exhibitionId:""
      },
      addEnterpriseDialogVisible:false,
      state:"",
      searchList:[],
      searchInput:false,
      exhibitorName:"",
      baseurl: this.$store.state.env.cos,
      yu: "/" + this.$store.state.env.domain,
      enterpriseList:[],
      pageStatus:1,
      id:'',
      // 展商列表
      exhibitorList:[],
    //   对话框表格多选列表
      multipleSelection:[]


    };
  },
  activated() {
    console.log(1);
  },
  created() {
  },

  methods: {
    /**
     * 绑定row-key 表格中每一条数据是唯一值
     * @param row
     * @returns {*}
     */
    getRowKey(row) {
      return row.id;
    },

    /**
     * 展商配置资料
     */
    handleView(row){
      console.log(this.$route.query.id)
      console.log(row.id)
      this.$router.push({
        path: "/exhibitor-configuration", query: {exhibitionId:this.$route.query.id,exhibitorId:row.id}
      })
    },
    resetList(){
      this.search={
      },
      this.queryList({
        page:1,
        pageSize:10
      })
    },
    // 多选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
      this.addEnterpriseDialogVisible=false
    },
    /**
     * 对话框表格多选
     * @param val
     */
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection=val
      // this.multipleSelection=JSON.parse(localStorage.getItem("selectArr")) || [];
      // this.multipleSelection.forEach((row)=>{
      //   this.$refs.multipleTable.toggleRowSelection(row, true);
      // })
      // // this.multipleSelection=val
      // localStorage.setItem("selectArr", JSON.stringify(this.multipleSelection));

    },
    onEdit(row) {
      this.$router.push({ name: "exhibitior_list", query: { id: row.id } });
    },
    async getExhibitorList(val){
      this.exhibitorSearch.exhibitionId=this.$route.query.id
      console.log(this.exhibitorSearch)
      let { data: res } = await this.$http.get("/admin/Exhibition/getExhibitorList", {params: {...val,...this.exhibitorSearch}});

      if (res.errorCode == 200) {
        this.pageData=res.data.exhibition
        this.$refs.table.complete(res.data);
      }
    },

    toClose() {
      setTimeout(() => {
        this.searchInput = false;
      }, 300);
    },
    /**
     * 添加展商
     * @returns {Promise<void>}
     */
    async addEnterprise(){
      this.exhibitorForm.ids=this.multipleSelection.map(item=>item.id).join(',')
      this.exhibitorForm.exhibitionId=this.$route.query.id
      let { data: res } = await this.$http.post("/admin/Exhibition/addExhibitor",{...this.exhibitorForm});
      this.exhibitorForm={
        ids:"",
        exhibitionId:""
      },
          this.toggleSelection()
          this.multipleSelection=[]

      this.getlist()

    },
    // 获取展会列表
    getlist() {
      this.$refs.table.refresh();
      console.log(this.search)
      console.log(this.voteCategoryList)
    },
    // // 获取展会展商名单
    // getExhibitorList(){
    //   this.$refs.exhibitorTable.refresh();
    // },
    /**
     * 获取展商列表
     * @param val
     * @returns {Promise<void>}
     */
    async queryList(val) {
      let { data: res } = await this.$http.get("/admin/Exhibition/getAccountList", {
        params: { ...val, ...this.search },
      });
      if (res.errorCode == 200) {
        this.$refs.exhibitorTable.complete(res.data);
      }

    },
    /**
     * 删除展会
     * @param row
     * @returns {Promise<void>}
     */
    async onDelete(row) {
      await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      let { data: res } = await this.$http.post("/admin/Exhibition/delExhibitor", row);
      if (res.errorCode == 200) {
        this.$message.success(res.message);
        this.getlist();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.for {
    transform: scale(0.5);
    transform-origin: 0 0;
}
.tips {
    color: gray;
    span {
        color: red;
    }
}
</style>
