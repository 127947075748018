<template>
  <div class="sms_template_list">
    <el-card  >
      <nav class="input" style="width: auto">
        <el-button type="primary" size="small" @click="$router.push('/exhibition_add_exhibition')">新增展会</el-button>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
        <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
          <el-table-column prop="id" label="ID" width="80px"> </el-table-column>
          <el-table-column prop="name" label="展会名称" width="250px"> </el-table-column>
          <el-table-column prop="activity_name" label="关联活动"> </el-table-column>
          <el-table-column prop="create_time" label="创建时间" width="220px"> </el-table-column>
          <el-table-column label="操作"  width="450px">
            <template slot-scope="{ row }">
              <el-button @click="onEdit('partner_list',row)" type="text" icon="el-icon-user" size="mini">合作方名单</el-button>
              <el-button @click="onEdit('exhibitior_list',row)" type="text" icon="el-icon-user" size="mini">展商名单</el-button>
                            <el-button @click="handleDownloadFile(row)" type="text"  size="mini">展商海报和logo批量下载</el-button>

              <el-button @click="onEditExhi(row)" type="text" icon="el-icon-edit" size="mini">编辑</el-button>
              <el-button
                  @click="onDelete(row)"
                  type="text"
                  icon="el-icon-delete"
                  size="mini"
                  style="color: red;"
              >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </z-table>
    </el-card>
    <el-dialog
        title="添加展会"
        :visible.sync="addEnterpriseDialogVisible"
        width="30%"
        center>
      <el-input v-model="exhibitorForm.name" size="medium" style="width: 512px" placeholder="请输入展会名称" />
      <div class="input-detail" >
        <input placeholder="输入活动名称模糊查询" class="clear-input"
               v-model="state"  type="text" name="" id=""
               @input="querySearchAsync" @blur="toClose" />
        <div class="theSearchBox" v-show="searchInput">
          <div class="item flex-align" v-for="(item, index) in searchList" :key="item.id">
            <img :src="baseurl + yu +item.banner" alt="" />

            <div class="desc clamp-1">{{ item.title }}</div>
            <div class="btn" @click="add(item)">添加</div>
          </div>
<!--          <div class="foot">-->
<!--            <div class="add-text">搜索不到您要添加的企业，请联系客服添加</div>-->
<!--            <img src="https://static-cdn.yfchuhai.com/www.yfchuhai.com/static/pc/img/index/grounp/anan_code.jpg" alt="" />-->
<!--            &lt;!&ndash; <span style="flex: 1; font-size: 18px; color: #333333">搜索不到您要添加的企业</span>-->
<!--            <div class="undata" @click.stop="manuallyAdd">手动添加</div> &ndash;&gt;-->
<!--          </div>-->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="addEnterpriseDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addEnterprise">确 定</el-button>

  </span>
    </el-dialog>
    <el-dialog
        title="展商列表"
        :visible.sync="addEnterpriseVisible"
        width="60%"
        @close="toggleSelection()"
        center>
      <nav class="input" style="width: auto">
        <el-input v-model="exhibitorListSearch.keyword" size="medium" placeholder="请输入企业名称" />
      </nav>
      <nav class="input" style="width: 150px">
        <el-button size="small" @click="resetList">重置</el-button>
        <el-button type="primary" size="small" @click="$refs.exhibitorTable.reload()">{{ "搜索" }}</el-button>
      </nav>
      <z-table ref="exhibitorTable" v-model="exhibitorList" @query="getQueryList" fullscreenLoading>
        <el-table
            ref="multipleTable"
            :row-key="getRowKey"
            @selection-change="handleSelectionChange"
            :data="exhibitorList" style="width: 100%" border stripe :header-cell-style="rowClass">
          <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true">
          </el-table-column>
          <el-table-column prop="id" label="ID" > </el-table-column>
          <el-table-column prop="enterpriseName" label="绑定企业"> </el-table-column>
          <el-table-column prop="code" label="展位号"> </el-table-column>
          <el-table-column prop="eaName" label="账号"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        </el-table>
      </z-table>
      <span slot="footer" class="dialog-footer">
    <el-button @click="toggleSelection()">取 消</el-button>
        <el-button type="primary" @click="downloadFile">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import list from "/src/mixin/list.js";
import {debounce} from "@/utils/methods";
import Img from "@/views/enterprise/enterprise_cert_list/img.vue";
import exhibitor from "@/views/enterprise/exhibitor.vue";
import log from "@/views/activity/activity_speaker_ppt/log.vue";
export default {
  computed: {
    exhibitor() {
      return exhibitor
    }
  },
  components: {Img},
  mixins: [list],
  data() {
    return {
      // 展商列表
      exhibitorList:[],
      exhibitorListSearch:{
        keyword:''
      },
      search: {
      },
      addForm:{
        categoryId:"1",
        enterpriseId:""
      },
      exhibitorForm:{
        name:"",
        activityId:""
      },
      exhibitorSearch:{
        page:1,
        pageSize:10,
        keyword:"",
        exhibitionId:""
      },
      addEnterpriseDialogVisible:false,
      addEnterpriseVisible:false,
      state:"",
      searchList:[],
      searchInput:false,
      exhibitorName:"",
      baseurl: this.$store.state.env.cos,
      yu: "/" + this.$store.state.env.domain,
      enterpriseList:[],
      pageStatus:1,
      dialogType:1,
      currentId:0,


    };
  },
  activated() {
    console.log(1);
  },
  created() {
  },

  methods: {

    handleDownloadFile(row){
      this.currentId=row.id
      this.exhibitorListSearch.exhibitionId=row.id
      this.addEnterpriseVisible=true
    },
    /**
     * 绑定row-key 表格中每一条数据是唯一值
     * @param row
     * @returns {*}
     */
    getRowKey(row) {
      return row.id;
    },
    resetList(){
      this.exhibitorListSearch.keyword=''
          this.getQueryList({
            page:1,
            pageSize:10
          })
    },
    /**
     * 获取展商列表
     * @param val
     * @returns {Promise<void>}
     */
    async getQueryList(val) {
      let { data: res } = await this.$http.get("/admin/Exhibition/getExhibitorList", {
        params: { ...val, ...this.exhibitorListSearch },
      });
      if (res.errorCode == 200) {
        console.log(res.data.list)
        this.$refs.exhibitorTable.complete(res.data);
      }

    },
    // 多选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
      this.addEnterpriseVisible=false
    },
    /**
     * 对话框表格多选
     * @param val
     */
    handleSelectionChange(val) {
      this.multipleSelection=val
      // this.multipleSelection=JSON.parse(localStorage.getItem("selectArr")) || [];
      // this.multipleSelection.forEach((row)=>{
      //   this.$refs.multipleTable.toggleRowSelection(row, true);
      // })
      // // this.multipleSelection=val
      // localStorage.setItem("selectArr", JSON.stringify(this.multipleSelection));

    },
    /**
     * 物料下载
     */
    async downloadFile(row){
      this.addEnterpriseVisible=false
      console.log(this.multipleSelection)
     const data= await this.$http.get("/admin/Exhibition/batchDownloadExhibitorPosterLogo",{params:{
         exhibition_id:this.exhibitorListSearch.exhibitionId,
         ids:this.multipleSelection.map(item=>item.id).join(',')
       }});

       const url = data.data.data.url

      if (url){
        let link = document.createElement("a"); //创建a标签
        link.style.display = "none"; //使其隐藏
        link.href = url; //赋予文件下载地址
        link.setAttribute("download", url); //设置下载属性 以及文件名
        document.body.appendChild(link); //a标签插至页面中
        link.click(); //强制触发a标签事件
      }
    },
    onEditExhi(row){
      console.log(row);
      this.$router.push({ name: "exhibition_add_exhibition", query: { id: row.id } });
    },
    onEdit(url,row) {
      this.$router.push({ name:url, query: { id: row.id } });
    },
    async exhibitorList(){
      let { data: res } = await this.$http.post("/admin/Exhibition/getExhibitorList",{...this.exhibitorSearch});

    },

    /**
     *
     */
    handleExhibitorList(row){
      this.pageStatus=2
      this.exhibitorSearch.exhibitionId=row.id
      this.exhibitorList()

    },
    /**
     *活动搜索
     */
    querySearchAsync: debounce(async function () {
      this.state=this.state.trim()
      const { data } = await this.$http.get(
          '/admin/Activity/getList' ,{params:{
              page:'1',
              pageSize:'5',
              eventDate: "",
              publishDate: "",
              title:this.state,
              publishStatus: "",
              checkStatus: "",
              acId: "",
              orvalueder: ""}}
      );
      this.searchList = data.data.list;
      this.searchInput = true;
    }),

    // 关联活动
    async add(row) {
      this.state=row.title
      this.exhibitorForm.activityId=row.id
      this.searchInput=false
    },
    toClose() {
      setTimeout(() => {
        this.searchInput = false;
      }, 300);
    },
    /**
     * 添加展会
     * @returns {Promise<void>}
     */
    async addEnterprise(){
      console.log(this.dialogType)
      if(this.dialogType){
        let { data: res } = await this.$http.post("/admin/Exhibition/addExhibition",{...this.exhibitorForm});
      }else {
        let { data: res } = await this.$http.post("/admin/Exhibition/editExhibition",{...this.exhibitorForm});

      }
      this.exhibitorForm={
        name:"",
        activityId:""
      },
          this.dialogType=1
          this.state=""
      this.addEnterpriseDialogVisible=false
      this.getlist()

    },
    // 获取展会列表
    getlist() {
      this.$refs.table.refresh();
      console.log(this.search)
      console.log(this.voteCategoryList)
    },
    // 获取展会展商名单
    getExhibitorList(){
      this.$refs.exhibitorTable.refresh();
    },

    async queryList(val) {
      let { data: res } = await this.$http.get("/admin/Exhibition/getExhibitionList", {
        params: { ...val, ...this.search },
      });
      if (res.errorCode == 200) {
        // this.$message.success(res.message);
        this.$refs.table.complete(res.data);
      }
    },
    /**
     * 删除展会
     * @param row
     * @returns {Promise<void>}
     */
    async onDelete(row) {
      await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      let { data: res } = await this.$http.post("/admin/Exhibition/delExhibition", row);
      if (res.errorCode == 200) {
        this.$message.success(res.message);
        this.getlist();
      }
    },
  },
};
</script>

<style lang="less" scoped>

.logo-img{
  width: 60px;
  height: 60px;
}
.input-detail {
  margin-top: 20px;
  width: 98%;
  height: 38px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  padding-left: 16px;
  position: relative;
  margin-bottom: 30px;
  z-index: 2;

  input {
    &::placeholder {
      color: #9EA2B5;
    }
  }

  input {
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    &::-webkit-input-placeholder {
      font-size: 14px;
      font-weight: 500;
      color: #999999;
    }
  }
}
:deep(.el-card .el-card__body){
  padding: 0 30px;
}
.input-tip {
  color: #9EA2B5;
  font-size: 14px;
  margin-top: 17px;
  line-height: 1.5;
  span{
    color: #E2241B;
  }
}
.theSearchBox {
  width: 368px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px 25px;
  position: absolute;
  top: 47px;
  left: 0px;

  .undata {
    width: 89px;
    height: 34px;
    background: #0a7ff2;
    border-radius: 17px;
    color: #fff;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
  }

  .foot {
    margin-top: 20px;
    text-align: center;

    .add-text {
      color: #333333;
      font-size: 16px;
    }

    img {
      width: 165px;
      height: 165px;
      margin-top: 30px;
    }


  }
  .item {
    // margin-bottom: 30px;
    padding: 15px 0;
    border-bottom: 1px solid rgba(229, 229, 229, 0.6);

    &:first-child {
      padding-top: 0;
    }

    img {
      width: 54px;
      height: 54px;
      border-radius: 10px;
      flex-shrink: 0;
    }

    .desc {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      margin-left: 22px;
      width: 154px;
    }

    .btn {
      width: 90px;
      height: 35px;
      border: 1px solid #0a7ff2;
      border-radius: 17px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #0a7ff2;
      line-height: 35px;
      margin-left: auto;
      flex-shrink: 0;
    }
  }
}
.list {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 10px;
  .list-item {
    flex-direction: column;
    width: 100px;
    height: 100px;
    background: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;

    &:hover {
      .box {
        display: flex;
      }
    }

    >img {
      width: 80px;
      height: 80px;
      border-radius: 6px;
      margin-bottom: 5px;
    }

    .desc {
      width: 90px;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow:ellipsis;
      text-align: center;
    }

    .box {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);

      .btn {
        width: 100px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        background: #ffffff;
        border-radius: 21px;
        color: #0a7ff2;
        font-size: 18px;
      }
    }
  }
}
</style>
