<template>
  <div class="sms_template_list_add">
    <el-card >
     <div class="flex-align">
       <el-page-header @back="goBack" >
       </el-page-header>
       <div class="title1"><span v-if="id" >{{exhibitionForm.name}}</span>
         <span style="margin: 5px;font-weight: 600">/</span>
         {{activeList.find(item=>item.active== active).label}}</div>
     </div>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-tabs tab-position="left" v-model="active"   :before-leave="beforeLeave">
        <el-tab-pane label="基础信息" name="information">
          <el-form ref="exhibitionForm" :model="exhibitionForm" label-width="150px" :inline="false" size="normal">
            <el-form-item label="关联活动">
              <el-col :span="8">
                <fuzzy-selection ref="fuzzySelection1" :value.sync="exhibitionForm.activity_id" :type="4" clearable
                                 tipName="活动" />
              </el-col>
            </el-form-item>
            <el-form-item label="展会名称">
              <el-col :span="8">
                <el-input v-model="exhibitionForm.name" show-word-limit />
              </el-col>
            </el-form-item>
            <el-form-item label="展会指引">
              <el-col :span="2">
                <el-select v-model="exhibitionForm.exhibition_guide.province" placeholder="请选择" @change="ci">
                  <el-option
                      v-for="item in province"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                  />
                </el-select>
              </el-col>
              <el-col :span="2" class="ml">
                <el-select v-model="exhibitionForm.exhibition_guide.city" placeholder="请选择" @change="cs">
                  <el-option v-for="item in city" :key="item.code" :label="item.name" :value="item.code" />
                </el-select>
              </el-col>
              <el-col :span="2" class="ml">
                <el-select v-model="exhibitionForm.exhibition_guide.district" @change="$forceUpdate()">
                  <el-option
                      v-for="item in district"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                  />
                </el-select>
              </el-col>
              <el-col :span="6" class="ml" style=" padding-right: 30px;">
                <el-autocomplete
                    v-model="exhibitionForm.exhibition_guide.detailAddress"
                    style="width: 100%"
                    class="in"
                    placeholder="请输入详细地址"
                    :fetch-suggestions="querySearch"
                    @input="getAddress"
                    @select="mySelect"
                />
              </el-col>

              <el-col :span="2" class="ml">
                <el-input v-model="exhibitionForm.exhibition_guide.location.lat" disabled placeholder="坐标x" />
              </el-col>
              <el-col :span="2" class="ml">
                <el-input v-model="exhibitionForm.exhibition_guide.location.lng" disabled placeholder="坐标y" />
              </el-col>

            </el-form-item>
            <el-form-item  ref="address_b2" label="地图">
              <Map style="width: 675px;" :addme="exhibitionForm.exhibition_guide.district" @position="getMsgFormSon" />
            </el-form-item>
            <el-form-item label="交通指引">
              <el-col :span="8">
                <el-form ref="exhibitionForm" :model="trafficForm" label-width="100px" :inline="false" size="normal">
                  <el-form-item label="地铁指引：" style="margin-bottom: 10px">
                    <el-input v-model="trafficForm.subway" show-word-limit />
                  </el-form-item>
                  <el-form-item label="停车指引：" style="margin-bottom: 10px">
                    <el-input v-model="trafficForm.station" show-word-limit />
                  </el-form-item>
                  <el-form-item label="公交指引：" >
                    <el-input v-model="trafficForm.public" show-word-limit />
                  </el-form-item>
                </el-form>
              </el-col>
            </el-form-item>
            <el-form-item label="物料上传截止时间">
              <el-col :span="8">
                <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                    v-model="exhibitionForm.material_end_time"
                    type="datetime"
                    placeholder="选择截止上传日期时间">
                </el-date-picker>
              </el-col>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="海报模版" name="poster">
          <el-col :span="8" style="display: flex;min-height: 470px">
<div style="width: 140px;text-align: end;float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;">海报模版</div>
            <uplist
                :action="action"
                :list="this.exhibitionForm.poster"
                @changes="c_img"
            />
          </el-col>
        </el-tab-pane>
        <el-tab-pane label="优惠券" name="coupon">
          <el-form ref="couponForm" :model="couponForm" :rules="rule" label-width="150px" :inline="false" size="normal">
            <el-form-item label="优惠券名称" prop="name" >
              <el-col :span="8">
                <el-input v-model="couponForm.name" show-word-limit />
              </el-col>
            </el-form-item>
            <el-form-item label="有效期" prop="bonus">
              <el-date-picker v-model="couponForm.time" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"
                              type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="优惠券数量" prop="number">
              <el-col :span="8">
                <el-input-number v-model="couponForm.number" :step="1"></el-input-number>
              </el-col>
            </el-form-item>
            <el-form-item label="优惠方式" prop="discountType">
              <el-radio-group v-model="couponForm.discountType" :disabled="couponForm.type == 2">
                <el-radio :label="0">折扣减免</el-radio>
                <el-radio :label="1">定额减免</el-radio>
              </el-radio-group>
              <div style="height:10px"></div>
              原价减
              <el-select style="width:100px" v-if="couponForm.type == 2" size="small" v-model="couponForm.discountValue">
                <el-option :value="20" label="20"> </el-option>
                <el-option :value="10" label="10"> </el-option>
              </el-select>
              <el-input v-else size="small" v-model="couponForm.discountValue" style="width:100px"></el-input>
              {{ couponForm.discountType == 0 ? "%" : "元" }}
            </el-form-item>
            <el-form-item label="使用限制">
              <el-input size="small" style="width:100px" v-model="couponForm.limit"></el-input>
              每人可以领取多少张
              <br />
              <div class="warning" style="color:#fda400;line-height:30px">
                若不限次数，请填0
              </div>
            </el-form-item>
            <el-form-item label="类型">
              <el-radio-group v-model="couponForm.type" @change="typeChange">
                <!-- <el-radio :label="0">通用</el-radio> -->
                <el-radio :label="1">活动</el-radio>
                <el-radio :label="2">VIP会员</el-radio>
              </el-radio-group>
            </el-form-item>
            <template v-if="couponForm.type == 1">
              <el-form-item label="选择活动">
                <el-col :span="8">
                  <fuzzy-selection ref="fuzzySelection" :value.sync="couponForm.activityId" :type="4" clearable
                                   tipName="活动" />
                </el-col>
              </el-form-item>
              <el-form-item label="适用票种" prop="ticketIds">
                <el-checkbox-group v-model="couponForm.ticketIds">
                  <!-- <el-checkbox label="-1">全部</el-checkbox> -->
                  <el-checkbox :label="item.id + ''" v-for="(item, index) in tickList" :key="index">{{
                      item.name
                    }}</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="票种类型" prop="ticketType">
                <el-radio-group v-model="couponForm.ticketType">
                  <el-radio :label="0">单人票</el-radio>
                  <el-radio :label="1">团体票</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="小程序" prop="miniprogramType">
                <el-radio-group v-model="couponForm.miniprogramType">
                  <!-- <el-checkbox label="-1">全部</el-checkbox> -->
                  <el-radio :label="item.id" v-for="(item, index) in [
                { id: 0, name: '扬帆小程序' },
                { id: 1, name: 'PAGC小程序' },
              ]" :key="index">{{ item.name }}</el-radio>
                </el-radio-group>
              </el-form-item>
            </template>
            <el-form-item label="描述">
              <el-col :span="8">
                <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="couponForm.desc"> </el-input>
              </el-col>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="自定义表单" name="custom_form">
          <el-tabs v-model="customFormIndex" type="card" editable @edit="handleTabsEdit">
            <el-tab-pane
                v-for="(item, index) in customForm"
                :label="item.title"
                :name="item.name"
                :key="item.name"
            >
             表单名称： <el-input style="width: 400px;margin: 20px 0;" v-model="item.title"></el-input>
              <new-form v-if="item.content" :ref="`newForm${item.name}`" />
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
      </el-tabs>


      <el-button type="primary" size="small" style="margin:50px 250px " @click="onSubmit">{{ "提交" }}</el-button>
      <!-- <el-button size="small" @click="preview">{{ "预览" }}</el-button> -->
    </el-card>
    <el-dialog title="展商列表" :visible.sync="addEnterpriseDialogVisible" width="60%" @close="toggleSelection()" center>
      <nav class="input" style="width: auto">
        <el-input v-model="search.keyword" size="medium" placeholder="请输入企业名称" />
      </nav>
      <nav class="input" style="width: 150px">
        <el-button size="small" @click="resetList">重置</el-button>
        <el-button type="primary" size="small" @click="$refs.exhibitorTable.reload()">{{ "搜索" }}</el-button>
      </nav>
      <z-table ref="exhibitorTable" v-model="exhibitorList" @query="queryList" fullscreenLoading>

        <el-table ref="multipleTable" @selection-change="handleSelectionChange" :data="exhibitorList"
          style="width: 100%" border stripe :header-cell-style="rowClass">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="enterpriseName" label="绑定企业"> </el-table-column>
          <el-table-column prop="name" label="账号"> </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间"> </el-table-column>
          <!--            <el-table-column label="操作">-->
          <!--              <template slot-scope="{ row }">-->
          <!--                <el-button-->
          <!--                    @click="onDelete(row)"-->
          <!--                    type="text"-->
          <!--                    icon="el-icon-delete"-->
          <!--                    size="mini"-->
          <!--                    style="color: red;"-->
          <!--                >删除</el-button>-->
          <!--              </template>-->
          <!--            </el-table-column>-->
        </el-table>
      </z-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toggleSelection()">取 消</el-button>
        <el-button type="primary" @click="addEnterprise">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import list from "/src/mixin/list.js";
import rules from "@/utils/rules";
import { debounce } from "@/utils/methods";
import Img from "@/views/enterprise/enterprise_cert_list/img.vue";
import exhibitor from "@/views/enterprise/exhibitor.vue";
import params from "@/views/goods/params.vue";
import fuzzySelection from "@/components/fuzzySelection";
import uplist from '@/components/uplist/index'
import UpImg from "@/components/global/upImg/index.vue";
import activity from "@/router/module/activity";
import Map from "@/components/welcome.vue";
import newForm from "@/components/newForm/container.vue";

export default {
  computed: {
    exhibitor() {
      return exhibitor
    }
  },
  components: {newForm, Map, UpImg, Img, fuzzySelection ,uplist,},
  mixins: [list],
  data() {
    return {
      // 自定义表单
      customFormIndex:'0',
      tabIndex: 2,
      customForm:[
        {
          title: '表单1',
          name: '0',
          content: []
        }
      ],
      // 地图

      active:'information',
      activeList:[
        {
          active:'information',
          label:'基础信息'
        },
        {
          active:'poster',
          label:'海报模版'
        },
        {
          active:'coupon',
          label:'优惠券'
        },
        {
          active:'custom_form',
          label:'自定义表单'
        },
      ],
      tickList: [],
      trafficForm:{
        public:'',
        station:'',
        subway:''
      },
      exhibitionForm: {
        activity_id: "",
        poster: [],
        name: "",
        material_end_time:'',
        exhibition_guide: {
          province: "",
          district: "",
          city: "",
          location:{
            lat:'',
            lng:''
          },
acId:3
        },
      },
      province: [],
      district: [],
      city: [],
      id: '',
      couponForm: {
        name: "",
        discountType: "",
        ticketIds: [],
        time: [],
        beginTime: "",
        endTime: "",
        number: "",
        limit: "0",
        discountValue: "",
        desc: "",
        activityId: "",
        bonus: "",
        type: 1,
        miniprogramType: 0,
        ticketType: 0,
      },
      rule: rules.reqAll({
        name: "请输入优惠券名称",
        ticketIds: "请选择票种",
        logo: "请输入企业logo",
        city: "请输入省份和城市",
        people: "请输入公司人事",
        financing: "请输入融资情况",
        type: "请输入类型",
        business: "请输入行业",
        serverArea: "请输入业务区域",
        companyDesc: "请输入企业介绍",
        intro: "请输入企业简介",
      }),
      action: {
        action: "bonus",
      },
      pageData: {
        name: '展商活动'
      },
      search: {
      },
      addForm: {
        categoryId: "1",
        enterpriseId: ""
      },
      exhibitorForm: {
        ids: "",
        exhibitionId: ""
      },
      exhibitorSearch: {
        keyword: "",
        exhibitionId: ""
      },
      addEnterpriseDialogVisible: false,
      state: "",
      searchList: [],
      searchInput: false,
      exhibitorName: "",
      baseurl: this.$store.state.env.cos,
      yu: "/" + this.$store.state.env.domain,
      enterpriseList: [],
      pageStatus: 1,
      // 展商列表
      exhibitorList: [],
      //   对话框表格多选列表
      multipleSelection: [],
      first: true,
      queryData:[]
    };
  },
  activated() {
  },
  created() {
    this.getp();
    this.getCategoryForm('coupon')
    this.getCategoryForm('poster')
    this.getCategoryForm('information')
    this.getCategoryForm('custom_form')
  },
  watch: {
    "couponForm.activityId"(newValue, oldValue) {
      this.findTick();
      if (this.first) {
        return;
      }
      this.couponForm.ticketIds = [];
    },
    "exhibitionForm.activity_id"(newValue, oldValue) {
      const  options = this.$refs.fuzzySelection1.options.find(item=>item.id==this.exhibitionForm.activity_id)
      if (!this.exhibitionForm.name){
        this.exhibitionForm.name=this.$refs.fuzzySelection1.options.find(item=>item.id==this.exhibitionForm.activity_id).title
        if(options?.province){
          this.exhibitionForm.exhibition_guide.province=options.province
          this.exhibitionForm.exhibition_guide.city=options.city
         if(options?.location){
           this.exhibitionForm.exhibition_guide.location={
             lat: options.location.split(",")[0],
             lng: options.location.split(",")[1],
           }
         }
          this.cs()
          this.getAddress()
          this.$forceUpdate()
          this.exhibitionForm.exhibition_guide.city=options.city
          this.getc()
          this.exhibitionForm.exhibition_guide.district=options.district
          this.exhibitionForm.exhibition_guide.detailAddress=options.detailAddress
        }
      }
    },
    // "form.type"(val) {
    //     if (val == 2) {
    //         this.form.discountValue = 20;
    //         this.form.discountType = 0;
    //     }
    // },
  },
  methods: {
    /**
     * 自定义表单
     * @param targetName
     * @param action
     */
    handleTabsEdit(targetName, action) {
      if (action === 'add') {
        let newTabName = ++this.tabIndex + '';
        this.$prompt('请输入表单名称', '新增', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          this.customForm.push({
            title: value,
            name: newTabName,
            content: 'New Tab content'
          });
          this.customFormIndex = newTabName;
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
      }
      if (action === 'remove') {
        let tabs = this.customForm;
        let activeName = this.customFormIndex;
        if (activeName === targetName) {
          tabs.forEach((tab, index) => {
            if (tab.name === targetName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }
        this.customFormIndex = activeName;
        this.customForm = tabs.filter(tab => tab.name !== targetName);
      }
    },
    querySearch(queryString, cb) {
      cb(this.queryData);
    },
    async getAddress() {
      const getO = JSON.parse(sessionStorage.getItem("center"));
      const url = `https://apis.map.qq.com/ws/place/v1/search?boundary=nearby(${getO.lat},${getO.lng},1000)&page_size=5&page_index=1&keyword=${this.exhibitionForm.exhibition_guide.detailAddress}&key=OUNBZ-K44EX-WOR4U-ZW3ET-6ALJ2-6HBNK`;
      const res = await this.$jsonp(url, {
        output: "jsonp",
      });
      this.queryData = [];
      // [...res.data, { value: res.data.title }, { address: res.data.address }]
      if (res.data) {
        res.data.forEach((item) => {
          this.queryData.push({
            value: item.title,
            address: item.address,
            point: item.location,
          });
        });
      }
    },
    getMsgFormSon(e) {
      this.exhibitionForm.exhibition_guide.location = e;
      // .form.location = e.lat + ',' + e.lng
    },
    /**
     * 地图
     * @param newIndex
     * @param oldIndex
     * @returns {boolean}
     *
     */
    ci() {
      this.getc();
      this.exhibitionForm.exhibition_guide.city = "";
      this.exhibitionForm.exhibition_guide.district = "";
    },
    cs() {
      this.bus.$emit("getmap", this.exhibitionForm.exhibition_guide.city);
      this.$forceUpdate();
      this.getqu();
      this.exhibitionForm.exhibition_guide.district = "";
    },
    getp() {
      this.$http.get("/admin/Area/getList").then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.province = res.data;
        }
      });
    },
    mySelect(e) {
      this.exhibitionForm.exhibition_guide.location = e.point;
      this.bus.$emit("pushaddress", e.point);
    },
    getc() {
      if (this.exhibitionForm.exhibition_guide.province) {
        this.$http.get("/admin/Area/getList?code=" + this.exhibitionForm.exhibition_guide.province).then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.city = res.data;
          }
        });
      }
    },
    getqu() {
      if (this.exhibitionForm.exhibition_guide.city.length != 0) {
        this.$http.get("/admin/Area/getList?code=" + this.exhibitionForm.exhibition_guide.city).then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.district = res.data;
          }
        });
      }
    },
    beforeLeave (newIndex, oldIndex) {
      if (!this.id){
        this.$message('新增展会请先完成基础信再进行其他配置')
        return false
      }
    },
    c_img(val) {
      this.exhibitionForm.poster = val
    },
    async getCategoryForm(category){
      if (!this.$route.query.id) {
        return;
      }
      this.id = this.$route.query.id;
      const { data: res } = await this.$http.get("/admin/Exhibition/getExhibitionInfo",{ params:{
          exhibition_id:this.id,
          category
        }});
      console.log(res.data)
      if(category == 'information'){
        this.exhibitionForm.activity_id= res.data.activity_id,
            this.exhibitionForm.material_end_time=res.data.material_end_time,
            this.exhibitionForm.exhibition_guide=res.data.exhibition_guide,
            this.exhibitionForm.activity_name=res.data.activity_name,
            this.exhibitionForm.name=res.data.name,
        this.trafficForm=JSON.parse(res.data.traffic_guidance)
           this.$nextTick(() => {
             if (!this.$refs.fuzzySelection1) {
               return;
             }
             this.$refs.fuzzySelection1.options = [{ id: this.exhibitionForm.activity_id, title: this.exhibitionForm.activity_name }];
             this.$refs.fuzzySelection1.val = this.exhibitionForm.activity_id;
           });
        this.bus.$emit("pushaddress", this.exhibitionForm.exhibition_guide.location);
        this.getc()
        this.getp()
        this.getqu()
        sessionStorage.setItem("center", JSON.stringify(this.exhibitionForm.exhibition_guide.location))
      }else  if (category=='poster'){
        this.exhibitionForm.poster =res.data?.poster?JSON.parse(res.data.poster):[]
      }else if(category == 'coupon'){
        this.couponForm =res.data?.coupon?JSON.parse(res.data?.coupon):{
          name: "",
          discountType: "",
          ticketIds: [],
          time: [],
          beginTime: "",
          endTime: "",
          number: "",
          limit: "0",
          discountValue: "",
          desc: "",
          activityId: "",
          bonus: "",
          type: 1,
          miniprogramType: 0,
          ticketType: 0,
        }
        this.$nextTick(() => {
             if (!this.$refs.fuzzySelection) {
               return;
             }
             this.$refs.fuzzySelection.options = [{ id: this.couponForm.activityId, title: this.couponForm.activityName }];
             this.$refs.fuzzySelection.val = this.couponForm.activityId;
           });
        }else  if (category == 'custom_form'){
        if(res.data.custom_form){
          this.customForm=JSON.parse(res.data.custom_form).map((item,index)=>{
            return {
              name:String(index),
              title:item.name,
              content:item.items
            }
          })
        }
        this.$nextTick(()=>{
          this.customForm.forEach((item,index)=>{
            this.$refs[`newForm${item.name}`][0].configFormData.list=item.content
          })
        })
      }
    },
    async findTick() {
      var { data: res } = await this.$http.get(
        `/admin/Activity/getTicketList?activityId=${this.couponForm.activityId}&page=1&pageSize=5000`
      );
      this.tickList = res.data.list;
      this.first = false;
    },
    typeChange() {
      if (this.couponForm.type == 2) {
        this.couponForm.discountValue = 20;
        this.couponForm.discountType = 0;
      }
    },
    async onSubmit(category) {
      var url;
      if (this.id) {
        url = "/admin/Exhibition/editExhibition";
      } else {
        url = "/admin/Exhibition/addExhibition";
      }
      let queryparams = {}
      if(this.active == 'information'){
        queryparams={
          exhibition_id: this.id,
          name: this.exhibitionForm.name,
          activity_id: this.exhibitionForm.activity_id,
          material_end_time:this.exhibitionForm.material_end_time,
          traffic_guidance:JSON.stringify(this.trafficForm),
          exhibition_guide:this.exhibitionForm.exhibition_guide,
          category:this.active
        }
      }else  if (this.active=='poster'){
        queryparams={
          exhibition_id: this.id,
          poster:JSON.stringify(this.exhibitionForm.poster),
          category:this.active
        }
      }else if(this.active == 'coupon'){
        const  coupon ={
          ...this.couponForm,
          beginTime: this.couponForm.time[0],
          endTime: this.couponForm.time[1],
        }
        queryparams={
          exhibition_id: this.id,
          coupon:JSON.stringify(coupon),
          category:this.active
        }
      }else  if(this.active == 'custom_form'){
        const  custom_form=this.customForm.map((item,index)=>{
          return {
            items:this.$refs[`newForm${item.name}`][0].configFormData.list,
            name:item.title
          }
        })
        queryparams={
          exhibition_id: this.id,
          category:this.active,
          custom_form:JSON.stringify(custom_form)
        }
      }
      var { data: res } = await this.$http.post(url, {...queryparams});
      if (res.errorCode == 200) {
        this.$message.success(res.message);
        if(!this.id){
          this.$router.push({ path: "/exhibition_list"});
        }else {
          this.getCategoryForm(this.active)
        }
      }
    },
    /**
     * 对话框表格多选
     * @param val
     */
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    onEdit(row) {
      this.$router.push({ name: "exhibitior_list", query: { id: row.id } });
    },
    async getExhibitorList(val) {
      this.exhibitorSearch.exhibitionId = this.$route.query.id
      let { data: res } = await this.$http.get("/admin/Exhibition/getExhibitorList", { params: { ...val, ...this.exhibitorSearch } });

      if (res.errorCode == 200) {
        this.pageData = res.data.exhibition
        this.$refs.table.complete(res.data);
      }
    },

    toClose() {
      setTimeout(() => {
        this.searchInput = false;
      }, 300);
    },
    /**
     * 添加展会
     * @returns {Promise<void>}
     */
    async addEnterprise() {
      this.exhibitorForm.ids = this.multipleSelection.map(item => item.id).join(',')
      this.exhibitorForm.exhibitionId = this.$route.query.id
      let { data: res } = await this.$http.post("/admin/Exhibition/addExhibitor", { ...this.exhibitorForm });
      this.exhibitorForm = {
        ids: "",
        exhibitionId: ""
      },
        this.toggleSelection()
      this.multipleSelection = []

      this.getlist()

    },
    // 获取展会列表
    getlist() {
      this.$refs.table.refresh();
    },
    // // 获取展会展商名单
    // getExhibitorList(){
    //   this.$refs.exhibitorTable.refresh();
    // },
    /**
     * 获取展商列表
     * @param val
     * @returns {Promise<void>}
     */
    async queryList(val) {
      let { data: res } = await this.$http.get("/admin/Exhibition/getAccountList", {
        params: { ...val, ...this.search },
      });
      if (res.errorCode == 200) {
        this.$refs.exhibitorTable.complete(res.data);
      }

    },
    /**
     * 删除展会
     * @param row
     * @returns {Promise<void>}
     */
    async onDelete(row) {
      await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      let { data: res } = await this.$http.post("/admin/Exhibition/delExhibitor", row);
      if (res.errorCode == 200) {
        this.$message.success(res.message);
        this.getlist();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.for {
  transform: scale(0.5);
  transform-origin: 0 0;
}

.tips {
  color: gray;

  span {
    color: red;
  }
}
</style>
