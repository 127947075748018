<template>
  <div class="sms_template_list_add">
    <el-card>
      <el-page-header @back="goBack" style="margin-bottom: 20px;"></el-page-header>
      <nav class="input flex-align" style="width: auto">
        <div>
          {{pageData.name}}
        </div>
      </nav>
      <nav class="input" style="width: auto">
        <el-input v-model="exhibitorSearch.keyword" size="medium" placeholder="请输入企业名称" />
      </nav>
      <nav class="input" style="width: 150px">
        <el-button size="small" @click="reset">重置</el-button>
        <el-button type="primary" size="small" @click="$refs.table.reload()">{{ "搜索" }}</el-button>
      </nav>
      <nav class="input" style="width: auto">
        <el-button type="primary" size="small" @click="addEnterpriseDialogVisible=true">添加合作方</el-button>
      </nav>
    </el-card>
    <el-card  style="margin-top: 10px">
      <z-table ref="table" v-model="list" @query="getExhibitorList" fullscreenLoading>
        <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
          <el-table-column prop="id" label="ID"> </el-table-column>
          <el-table-column prop="enterpriseName" label="企业名称">
            <template #default="{row}">
              <!--              <el-popover-->
              <!--                  placement="top-start"-->
              <!--                  title="tips"-->
              <!--                  width="200"-->
              <!--                  trigger="hover"-->
              <!--                  :content="row.isUpdate?'展商更新了物料':'暂无更新'">-->
              <!--              </el-popover>-->
              <span style="position: relative">
               {{ row.enterprise_name }}
             </span>
            </template>
          </el-table-column>
          <el-table-column prop="number" label="合作方编号"> </el-table-column>
          <el-table-column prop="sort" label="排序"> </el-table-column>
          <el-table-column prop="time" label="创建时间"> </el-table-column>

<!--          <el-table-column prop="code" label="合作方编号号">-->
<!--            <template #default="{row}">-->
<!--              <Remark :row="row" url="/admin/Exhibition/editExhibitorCode" @getlist="getlist" rowKey="code"></Remark>-->
<!--            </template>-->
<!--          </el-table-column>-->


          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <el-button
                  @click="editPartner(row)"
                  type="text"
                  icon="el-icon-edit"
                  size="mini"
              >编辑</el-button
              >
              <el-button
                  @click="onDelete(row)"
                  type="text"
                  icon="el-icon-delete"
                  size="mini"
                  style="color: red;"
              >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </z-table>
    </el-card>
    <el-dialog
        title="添加合作方"
        :visible.sync="addEnterpriseDialogVisible"
        width="30%"
        @close="clearForm()"
        center>

      <el-form ref="partnerForm" :model="partnerForm" label-width="100px" :inline="false" size="normal">
        <el-form-item label="企业" style="margin-bottom: 10px">
          <div class="input-detail" >
            <input placeholder="输入企业名称模糊查询" class="clear-input"
                   v-model="partnerForm.enterprise_name"  type="text" name="" id=""
                   @input="querySearchAsync" @blur="toClose" />
            <div class="theSearchBox" v-if="searchInput">
              <div class="item flex-align" v-for="(item, index) in searchList" :key="item.id">
                <img :src="item.logo" alt="" />
                <div class="desc clamp-1">{{ item.name }}<span v-if="item.user_num && !item.is_auth">（有{{item.user_num }}人绑定）</span><el-tag style="margin-left: 5px" size="small" type="warning" v-if="item.is_auth">有管理员</el-tag><br><span style="font-size: 12px;color: slategrey">{{item.full_name}}</span></div>
                <div class="btn" @click="selectEnterprise(item)">添加</div>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="排序" style="margin-bottom: 10px">
          <el-input v-model="partnerForm.sort" show-word-limit />
        </el-form-item>
        <el-form-item label="合作方编号" style="margin-bottom: 10px">
          <el-input v-model="partnerForm.number" show-word-limit />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="addEnterpriseDialogVisible=false">取 消</el-button>
    <el-button type="primary" @click="addEnterprise">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>
<script>
import list from "/src/mixin/list.js";
import {debounce} from "@/utils/methods";
import Img from "@/views/enterprise/enterprise_cert_list/img.vue";
import exhibitor from "@/views/enterprise/exhibitor.vue";
import params from "@/views/goods/params.vue";
import {clear} from "core-js/internals/task";
import * as url from "url";
export default {
  computed: {
    exhibitor() {
      return exhibitor
    }
  },
  components: {Img},
  mixins: [list],
  data() {
    return {
      state:'',
      searchInput:false,
      partnerForm:{
        enterprise_id:'',
        exhibition_id:this.id,
        sort:'',
        number:'',
        enterprise_name:''
      },
      pageData:{
        name:'展商活动'
      },
      search: {
      },
      addForm:{
        categoryId:"1",
        enterpriseId:""
      },
      exhibitorForm:{
        ids:"",
        exhibitionId:""
      },
      exhibitorSearch:{
        keyword:"",
        exhibition_id:""
      },
      addEnterpriseDialogVisible:false,
      searchList:[],
      exhibitorName:"",
      baseurl: this.$store.state.env.cos,
      yu: "/" + this.$store.state.env.domain,
      enterpriseList:[],
      pageStatus:1,
      id:'',
      // 展商列表
      exhibitorList:[],
      //   对话框表格多选列表
      multipleSelection:[]


    };
  },
  activated() {
    console.log(1);
  },
  created() {
  },

  methods: {
    /**
     * 编辑
     */
    editPartner(row){
      this.partnerForm=row
      this.addEnterpriseDialogVisible=true
    },
    querySearchAsync: debounce(async function () {
      this.partnerForm.enterprise_name=this.partnerForm.enterprise_name.trim()
      const { data } = await this.$http.get(
          '/admin/Enterprise/getEnterpriseList' ,{params:{
              page:'1',
              pageSize:'100',
              type:'-1',
              business:'-1',
              serverArea:'-1',
              keyword:this.partnerForm.enterprise_name}}
      );
      console.log(data);
      this.searchList = data.data.list;
      this.searchInput = true;
    }),
    /**
     * 表单重置
     */
    clearForm(){
this.partnerForm={
        enterprise_id:'',
            exhibition_id:this.id,
            sort:'',
            number:'',
            enterprise_name:''
      }
      this.addEnterpriseDialogVisible=false
    },
    /**
     * 展商配置资料
     */
    handleView(row){
      console.log(this.$route.query.id)
      console.log(row.id)
      this.$router.push({
        path: "/exhibitor-configuration", query: {exhibitionId:this.$route.query.id,exhibitorId:row.id}
      })
    },
    resetList(){
      this.search={
      },
          this.queryList({
            page:1,
            pageSize:10
          })
    },

    /**
     * 对话框表格多选
     * @param val
     */
    handleSelectionChange(val) {
      this.multipleSelection=val
    },
    onEdit(row) {
      this.$router.push({ name: "exhibitior_list", query: { id: row.id } });
    },
    async getExhibitorList(val){
      this.exhibitorSearch.exhibition_id=this.$route.query.id
      console.log(this.exhibitorSearch)
      let { data: res } = await this.$http.get("/admin/Exhibition/getExhibitionCooperationList", {params: {...val,...this.exhibitorSearch}});

      if (res.errorCode == 200) {
        this.pageData=res.data.exhibition
        this.$refs.table.complete(res.data);
      }
    },

    toClose() {
      setTimeout(() => {
        this.searchInput = false;
      }, 300);
    },
    /**
     * 企业选择
     */
    selectEnterprise(item){
      this.partnerForm.enterprise_id=item.id
      this.partnerForm.enterprise_name = item.name
    },
    /**
     * 添加合作方
     * @returns {Promise<void>}
     */
    async addEnterprise(){
      let url = ''
      if(this.partnerForm.exhibition_id){
url='/admin/Exhibition/editExhibitionCooperation'
      }else {
        this.partnerForm.exhibition_id=this.$route.query.id
        url ="/admin/Exhibition/addExhibitionCooperation"

      }
      let { data: res } = await this.$http.post(url,{...this.partnerForm});
      this.clearForm()
      this.getlist()

    },
    // 获取展会列表
    getlist() {
      this.$refs.table.refresh();
    },
    // // 获取展会展商名单
    // getExhibitorList(){
    //   this.$refs.exhibitorTable.refresh();
    // },
    /**
     * 获取展商列表
     * @param val
     * @returns {Promise<void>}
     */
    async queryList(val) {
      let { data: res } = await this.$http.get("/admin/Exhibition/getAccountList", {
        params: { ...val, ...this.search },
      });
      if (res.errorCode == 200) {
        this.$refs.exhibitorTable.complete(res.data);
      }

    },
    /**
     * 删除展会
     * @param row
     * @returns {Promise<void>}
     */
    async onDelete(row) {
      await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      let { data: res } = await this.$http.post("/admin/Exhibition/deleteExhibitionCooperation", row);
      if (res.errorCode == 200) {
        this.$message.success(res.message);
        this.getlist();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.for {
  transform: scale(0.5);
  transform-origin: 0 0;
}
.tips {
  color: gray;
  span {
    color: red;
  }
}
.input-detail {
  height: 38px;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  padding-left: 16px;
  position: relative;
  z-index: 2;

  input {
    &::placeholder {
      color: #9EA2B5;
    }
  }

  input {
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    &::-webkit-input-placeholder {
      font-size: 14px;
      font-weight: 500;
      color: #999999;
    }
  }
}
.theSearchBox {
  width: 368px;
  height: 400px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px 25px;
  position: absolute;
  top: 47px;
  left: 0px;

  .undata {
    width: 89px;
    height: 34px;
    background: #0a7ff2;
    border-radius: 17px;
    color: #fff;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
  }

  .foot {
    margin-top: 20px;
    text-align: center;

    .add-text {
      color: #333333;
      font-size: 16px;
    }

    img {
      width: 165px;
      height: 165px;
      margin-top: 30px;
    }


  }
  .item {
    // margin-bottom: 30px;
    padding: 15px 0;
    border-bottom: 1px solid rgba(229, 229, 229, 0.6);

    &:first-child {
      padding-top: 0;
    }

    img {
      width: 54px;
      height: 54px;
      border-radius: 10px;
      flex-shrink: 0;
    }

    .desc {
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      margin-left: 22px;
      width: 190px;
    }

    .btn {
      width: 90px;
      height: 35px;
      border: 1px solid #0a7ff2;
      border-radius: 17px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #0a7ff2;
      line-height: 35px;
      margin-left: auto;
      flex-shrink: 0;
      cursor: pointer;
    }
  }
}
</style>
