import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VueCookies from "vue-cookies";
// 挂载Vuex
Vue.use(Vuex);
import { Loading, MessageBox, Message } from "element-ui";
// 创建VueX对象
const store = new Vuex.Store({
    state: {
        // 存放的键值对就是所要管理的状态
        name: sessionStorage.getItem("menu"),
        env: (sessionStorage.getItem("env") && JSON.parse(sessionStorage.getItem("env"))) || {
            cos: process.env.VUE_APP_STATIC,
            domain: process.env.VUE_APP_BASE_HOST,

        },
        btn_if: JSON.parse(localStorage.getItem("but")) || [],
        config: null,
        NormalList: null,
        phone: VueCookies.get("phone") || "",
        Did: "",
        counts: 0,
        enterprise_config: {},
        loading: "",
        adPosition: {
            //   0: '【PC】首页资讯',
            1: "【PC】资讯页",
            2: "【PC】快讯页",
            3: "【PC】资讯详情页",
            4: "【小程序】企服首页",
            5: "【PC】企服首页",
            6: "【小程序】合作页",
            7: "【小程序】首页弹窗",
            8: "【小程序】圈子首页弹窗",
            9: "【小程序】企服首页弹窗",
            10: "【小程序】合作首页弹窗",
            11: "【PC】首页banner和资讯之间广告位",
            12: "【PC】首页右侧侧栏广告位",
            13: "【PC】首页顶部",
            14: "【PC】报告页顶部",
        },
        adConfig: [],
        advertisingConfiguration: {},
        contactType: [],
    },
    getters: {
        imgUrl(state) {
            return "https://" + state.env.domain + "/admin/Upload/upload";
        },
        imgCol() {
            return process.env.VUE_APP_STATIC + process.env.VUE_APP_STATIC_DIR;
        },
    },
    watch: {
        data(newValue, oldValue) {},
    },
    mutations: {
        getConfiguration(state) {
            axios.get("/admin/User/getConfig").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    state.config = res.data;
                }
            });
            axios.get("/admin/WechatAssistant/getNormalList").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    state.NormalList = res.data;
                }
            });
            axios.get("/admin/Common/getConfigDictionaries?config=enterprise").then(({ data: res }) => {
                if (res.errorCode == 200) {
                    state.enterprise_config = res.data;
                }
            });
        },
        sLoad(state) {
            state.loading = Loading.service({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
        },
        eLoad(state) {
            state.loading.close();
        },
        setAdConfig(state, payload) {
            state.adConfig = payload;
        },
        setConfig(state, { key, value }) {
            state[key] = value;
        },
    },
    actions: {
        updateValue({ commit }, payload) {
            commit("updateValue", payload);
        },
        getconfig() {},
        checkGraphics() {
            return new Promise((resolve) => {
                // const langtion = 'zh-hk'
                const langtion = "zh";
                const captchaId = "2045288608"; // 腾讯滑块验证码appid
                // 生成一个滑块验证码对象
                const captcha = new TencentCaptcha(captchaId, async (res) => {
                    if (res.ret === 0) {
                        // 成功
                        resolve(res);
                    }
                });
                //  滑块多语言调用
                captcha.initOpts.forceLang = langtion;
                captcha.langFun();
                // 滑块显示
                captcha.show();
            });
        },
        getContactType(state) {
            return new Promise(async (resolve) => {
                let {
                    data: {
                        data: { fj_content },
                    },
                } = await axios.get("/api/Common/getFileJson", { params: { name: "contactType" } });
                fj_content = JSON.parse(fj_content);
                resolve(fj_content);
                state.commit("setConfig", { key: "contactType", value: fj_content });
            });
        },
        getProvince() {
            return new Promise(async (resolve) => {
                const { data: res } = await axios.get("/admin/Common/getArea");
                resolve(res);
            });
        },
        getCity(st, val) {
            return new Promise(async (resolve) => {
                const { data: res } = await axios.get("/api/Common/getArea?code=" + val);
                if (res.errorCode == 200) {
                    resolve(res);
                }
            });
        },
        $confirm(st, tip = "请确认审核通过？") {
            return new Promise((rel, rej) => {
                MessageBox.confirm(tip, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        rel();
                    })
                    .catch(() => {
                        Message({
                            type: "info",
                            message: "已取消",
                        });
                        rej();
                    });
            });
        },
        upLoad(accept = "xlsx") {
            return new Promise((resolve, reject) => {
                const input = document.createElement("input");
                input.type = "file";
                input.accept = accept;
                input.click();
                let that = this;
                input.onchange = async function() {
                    var file = this.files[0];
                    var formData = new FormData();
                    formData.append("file", file);
                    resolve(formData);
                };
            });
        },
        async getAddConfig({ state, commit }) {
            if (Object.keys(state.adConfig).length === 0) {
                let {
                    data: { data },
                } = await axios.get("/admin/HomeAd/getAdConfig");
                commit("setAdConfig", data);

                axios.get("/admin/Common/getConfigDictionaries?config=ad").then(({ data }) => {
                    state.advertisingConfiguration = data.data;
                });
            }
        },
    },
});
// const proxy = new Proxy(store.state,);
// store.watch(
//     (state) => state.btn_if,
//     (newValue, oldValue) => {
//         hasShowBind.forEach((item) => {
//             item();
//         });
//     }
// );

// let hasShowBind = [];
Vue.directive("hasShow", {
    inserted(el, binding) {
        if (!store.state.btn_if.includes(binding.value)) {
            el.style.display = "none";
        }
    },
});

export default store;
