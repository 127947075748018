<template>
  <div class="exhibitor-configuration">
    <el-card>
      <el-page-header @back="goBack" style="margin-bottom: 20px;"></el-page-header>
      <el-tabs v-model="active" tab-position="left" @tab-click="handleTab">
        <el-tab-pane label="展商基础信息" name="basicInformation">
           <span  style="position: relative" slot="label">
               展商基础信息
               <span class="top" v-if="status.basicInformation" style="position: absolute;top: -6px;
  right: -15px;
    width: 30px;
  height: 12px;
   background: linear-gradient(-50deg, #ff2f37, #ff8c18);
   border-radius: 100px;
    font-size: 10px;
 color: #fff;
    line-height: 11px;
   text-align: center;z-index: 99">new</span>
             </span>
          <el-tabs v-model="activeName"  @tab-click="handleClickTab">
            <el-tab-pane v-for="item in menu" :label="item.name" :name="item.sub">
              <span  style="position: relative" slot="label">
               {{ item.name }}
               <span class="top" v-if="item.is_update" style="position: absolute;top: -6px;
  right: -29px;
    width: 30px;
  height: 12px;
   background: linear-gradient(-50deg, #ff2f37, #ff8c18);
   border-radius: 100px;
    font-size: 10px;
 color: #fff;
    line-height: 11px;
   text-align: center;z-index: 99">new</span>
             </span>
              <itemCpn @approve="handleApprove" :data="item"></itemCpn>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="展商素材" name="sourceMaterial">
          <span  style="position: relative" slot="label">
               展商素材
               <span class="top" v-if="status.sourceMaterial" style="position: absolute;top: -6px;
  right: -15px;
    width: 30px;
  height: 12px;
   background: linear-gradient(-50deg, #ff2f37, #ff8c18);
   border-radius: 100px;
    font-size: 10px;
 color: #fff;
    line-height: 11px;
   text-align: center;z-index: 99">new</span>
             </span>
          <el-tabs v-model="activeName" @tab-click="handleClickTab" style="position: relative">
            <el-tab-pane v-for="item in menu" :label="item.name" :name="item.sub" >
             <span  style="position: relative" slot="label">
               {{ item.name }}
               <span class="top" v-if="item.is_update" style="position: absolute;top: -6px;
  right: -29px;
    width: 30px;
  height: 12px;
   background: linear-gradient(-50deg, #ff2f37, #ff8c18);
   border-radius: 100px;
    font-size: 10px;
 color: #fff;
    line-height: 11px;
   text-align: center;z-index: 99">new</span>
             </span>
              <itemCpn  :data="item" @approve="handleApprove"></itemCpn>

<!--              <el-button v-if="item.length &&item.sub=='material'" type="primary">-->
<!--                审批-->
<!--              </el-button>-->
<!--              改成单项审核-->
<!--              <div v-if="item.sub=='material' || item.sub=='guide' ">-->
<!--                <div v-if="item.is_completed">-->
<!--                 <div style="color: red;margin-bottom: 30px;" v-if="item.is_completed ==3">  审核不通过原因：{{item.approve_remarks}}</div>-->
<!--                </div>-->
<!--              </div>-->
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="展商海报" name="poster">
          <div class="poster">
            <div class="left">
              <el-form :model="queryParams" label-width="60px" style="flex:1">
                <el-form-item label="图片">
                  <up-img :banner.sync="queryParams.logo" :action="action" tipNew>
                    <template #tip>
                      <span>
                        请上传透明背景的logo，格式为png，大小控制在1MB以内，分辨率为600*300px
                      </span>
                    </template>
                  </up-img>
                </el-form-item>
                <el-form-item label="slogan">
                  <el-col :span="12">
                    <el-input placeholder="请输入slogan" v-model="queryParams.slogan"></el-input>
                  </el-col>
                </el-form-item>
              </el-form>
              <div class="btn-content">
                <el-button type="primary" @click="handleSave">保存海报</el-button>

                <el-button type="primary" @click="getExhibitionPosterPreview">预览海报</el-button>
                <el-button type="primary" @click="dialogVisible=true">自定义海报上传</el-button>
              </div>
            </div>
            <div class="right">
              <div class="title1 ">
<!--                <span>预览</span>-->
              </div>
              <div class="left-content">
                <el-image style="width: 100px;

        margin-right: 10px " v-for="item in posterList" :key="item"
                           :src="item" :zoom-rate="1.2" :max-scale="7" :min-scale="0.2" :preview-src-list="[item]"
                           :initial-index="4" fit="cover">
                </el-image>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
    <el-dialog
        title="海报上传"
        :visible.sync="dialogVisible"
        width="30%">
      <uplist
          :limit="100"
          :action="action"
          :list="this.queryParams.poster"
          @changes="c_img"
      />
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="getExhibitionPosterUpload">确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
        :title="title"
        :visible.sync="approveVisible"
        width="30%" @close="reset">
      <el-radio v-model="approveForm.is_approve" label="2">审核通过</el-radio>
      <el-radio v-model="approveForm.is_approve" label="3">审核不通过</el-radio>
      <el-input style="margin-top: 30px" v-if="approveForm.is_approve=='3'" v-model="approveForm.approve_refuse_reason" size="medium" placeholder="请输入审核不通过原因" />
      <span slot="footer" class="dialog-footer">
    <el-button @click="approveVisible = false">取 消</el-button>
    <el-button type="primary" @click="handleDetermine">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>
<script>
import itemCpn from "@/views/tool/exhibitor/item-cpn.vue";
import uplist from '@/components/uplist/index'
export default {
  data() {
    return {
      status:{
        basicInformation:0,
        sourceMaterial:0
      },
      // 自定义海报上传
      dialogVisible:false,
      title:'审批',
      // 审批
      approveVisible:false,
      // 审批批注
      approveForm:{
        is_approve:'2',
        approve_refuse_reason:'',
      },
      activeName: 'first',
      active: 'basicInformation',
      exhibitionId: "",
      exhibitorId: "",
      menu: [],
      posterList:[],
      postForm: {
        img: '',
        slogan: ''
      },
      action: {
        action: "system"
      },
      queryParams:{
        logo:"",
        slogan:""
      },

    };
  },
  components: {
    itemCpn,
    uplist
  },
  created() {
    this.exhibitionId = this.$route.query.exhibitionId
    this.exhibitorId = this.$route.query.exhibitorId
    console.log(this.exhibitionId, this.exhibitorId)
    this.active = localStorage.getItem('active') || 'basicInformation'
    if (this.active == 'poster'){
      this.getPosterDetails()
    }else {
      this.getData(this.active)
    }

  },

  methods: {

async getStatus(){
  let { data: res } = await this.$http.get("/admin/Exhibition/getExhibitorMenuStatus",{
    params: {
    exhibitor_id:this.$route.query.exhibitorId,
        exhibition_id
  :
    this.$route.query.exhibitionId
  }
})
  console.log(res.data)
  this.status=res.data

},

    /**
     * 审核弹窗
     * @param item
     */
    handleApprove(item){
      console.log(item)
      if (item.is_approve==1){
        this.approveForm.is_approve="2"
      }
      this.title=item.name + '审核'
      this.approveForm.only_key=item.key
      this.approveForm.approve_refuse_reason=item.approve_refuse_reason
      this.approveVisible=true
    },
    /**
     * 审批
     */
    async handleDetermine(){
      let { data: res } = await this.$http.post("/admin/Exhibition/materialApproval",{
        exhibitor_id:this.$route.query.exhibitorId,
        exhibition_id:this.$route.query.exhibitionId,
        type:this.active,
        sub:this.activeName,
        ...this.approveForm
      })
      this.$message('操作成功')
      this.approveVisible=false
      this.getData()
    },
    reset(){
      this.approveForm={
        is_approve:'2',
        approve_refuse_reason:'',
      }
    },

    /**
     * 自定义海报上传图片处理
     * @param val
     */
    c_img(val) {
      this.queryParams.poster = val
    },
    /**
     * 获取海报详情
     */
    async getPosterDetails(){
      let { data: res } = await this.$http.get("/admin/Exhibition/getPosterDetails?exhibitor_id="+this.$route.query.exhibitorId)
      this.queryParams=res.data

      this.posterList=res.data?.poster.length?res.data?.poster.split(','):[]
      this.queryParams.poster= res.data?.poster.length?res.data?.poster.split(',').map(item=>{
        return {
          name:item,
          url:item
        }
      }):[]

    },
    /**
     * 海报上传
     * @param e
     */
    async getExhibitionPosterUpload(){
      const url = this.queryParams.poster.map(item=>item.url).join(',')
      let { data: res } = await this.$http.post("/admin/Exhibition/exhibitionPosterUpload",{
        exhibitor_id:this.$route.query.exhibitorId,
        ...this.queryParams,
        url
      })
      this.dialogVisible=false
      this.getPosterDetails()
    },
    /**
     * 预览海报
     * @param e
     */
    async getExhibitionPosterPreview(){
      let { data: res } = await this.$http.post("/admin/Exhibition/exhibitionPosterPreview",{
        exhibitor_id:this.$route.query.exhibitorId,
        ...this.queryParams
      })
      console.log(res.data)
      this.posterList=res.data
    },
    handleSave(){
      this.$confirm('生成的海报可以点击自定义海报上传进行删减')
          .then(_ => {
            this.getExhibitionPosterSave()
          })
          .catch(_ => {});
    },
    /**
     * 保存海报
     * @param e
     */
    async getExhibitionPosterSave(){
      let { data: res } = await this.$http.post("/admin/Exhibition/exhibitionPosterSave",{
        exhibitor_id:this.$route.query.exhibitorId,
        ...this.queryParams
      })
      this.getPosterDetails()
    },
    handleClickTab(e){
      localStorage.setItem('activeName',e.name)
    },
    handleTab(e) {
      console.log(e);
      localStorage.setItem('active',e.name)
      localStorage.setItem('activeName','')
      if (e.name) {
        this.getData(e.name)
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    async getData(type) {
      let { data: res } = await this.$http.get("/admin/Exhibition/getBasicInformationConf", {
        params: {
          exhibitor_id:this.$route.query.exhibitorId,
          exhibition_id:this.$route.query.exhibitionId,
          type: type?type:this.active
        }
      })
      this.menu = res.data
      this.activeName =this.activeName =localStorage.getItem('activeName') ||  this.menu[0].sub
      this.active = localStorage.getItem('active') || 'basicInformation'
      this.getStatus()
    }
  },
};
</script>
<style scoped lang="less">
.poster {
  display: flex;
  min-height: 770px;

  .left {
    width: 49%;
    height: 570px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .left-content {
      text-align: center;
      margin-top: 69px;
      width: 50%;
      display: flex;


    }
  }

  .right {
    width: 49%;
    min-height: 770px;

    .title1 {
      display: flex;
      justify-content: space-between;
    }

    .left-content {
      text-align: center;
      margin-top: 69px;

      img {
        width: 336px;
        height: 596px;
      }
    }
  }
}
</style>